var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cover" }, [
    _c(
      "div",
      { staticClass: "partner-add" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              rules: _vm.formRules,
              model: _vm.formEditData,
              "label-width": "120px",
              "label-position": "top"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "请输入人员名称", prop: "userName" } },
              [
                _vm.from === "add"
                  ? _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          filterable: "",
                          remote: "",
                          placeholder: "请输入姓名",
                          "remote-method": _vm.getUserList,
                          loading: _vm.userLoading
                        },
                        on: { change: _vm.changerealName },
                        model: {
                          value: _vm.formEditData.userName,
                          callback: function($$v) {
                            _vm.$set(_vm.formEditData, "userName", $$v)
                          },
                          expression: "formEditData.userName"
                        }
                      },
                      _vm._l(_vm.authUserList, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.userName,
                            attrs: {
                              label: item.realName,
                              value: item.userName
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(item.realName) +
                                  "（" +
                                  _vm._s(item.userName) +
                                  "）" +
                                  _vm._s(item.orgName ? "-" + item.orgName : "")
                              )
                            ])
                          ]
                        )
                      }),
                      1
                    )
                  : _c("el-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.formEditData.realName,
                        callback: function($$v) {
                          _vm.$set(_vm.formEditData, "realName", $$v)
                        },
                        expression: "formEditData.realName"
                      }
                    })
              ],
              1
            ),
            _vm.from === "add"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "请输入人员所属组织", prop: "orgId" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "请选择所属组织",
                          clearable: "",
                          filterable: "",
                          remote: "",
                          "remote-method": _vm.getCompanyList,
                          loading: _vm.organizationLoading
                        },
                        on: { change: _vm.getCompanyName },
                        model: {
                          value: _vm.formEditData.orgId,
                          callback: function($$v) {
                            _vm.$set(_vm.formEditData, "orgId", $$v)
                          },
                          expression: "formEditData.orgId"
                        }
                      },
                      _vm._l(_vm.companyList, function(item) {
                        return _c("el-option", {
                          key: item.code,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "请选择角色", prop: "roleIds" } },
              [
                _c(
                  "el-select",
                  {
                    ref: "roleSelect",
                    staticStyle: { width: "100%" },
                    attrs: {
                      filterable: "",
                      placeholder: _vm.formEditData.roleName
                    },
                    on: { change: _vm.changeroleName },
                    model: {
                      value: _vm.formEditData.roleIds,
                      callback: function($$v) {
                        _vm.$set(_vm.formEditData, "roleIds", $$v)
                      },
                      expression: "formEditData.roleIds"
                    }
                  },
                  _vm._l(_vm.tagList, function(item) {
                    return _c("el-option", {
                      key: item.roleId,
                      attrs: { label: item.roleName, value: item.roleId }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { float: "right", "margin-left": "10px" },
                    attrs: {
                      loading: _vm.isAddingNow,
                      type: "primary",
                      size: "small"
                    },
                    on: { click: _vm.saveUser }
                  },
                  [_vm._v("确认")]
                ),
                _vm.from === "add"
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", "margin-left": "10px" },
                        attrs: {
                          loading: _vm.isAddingNow,
                          type: "primary",
                          size: "small"
                        },
                        on: { click: _vm.Continuetoadd }
                      },
                      [_vm._v("继续添加")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    staticStyle: { float: "right" },
                    attrs: { disabled: _vm.isAddingNow, size: "small" },
                    on: { click: _vm.cancle }
                  },
                  [_vm._v("取消")]
                )
              ],
              1
            ),
            _c("el-form-item", { staticStyle: { "margin-bottom": "0" } }, [
              _c(
                "p",
                { staticStyle: { color: "red", "line-height": "normal" } },
                [
                  _vm._v(" 项目内添加外部成员方法: "),
                  _c("br"),
                  _c("span", { staticClass: "info-span" }, [
                    _c("span", { staticClass: "info-span-layout" }, [
                      _c("span", [
                        _vm._v("1. 已有OA账号成员请从团队空间-成员-添加")
                      ]),
                      _c("span", [
                        _vm._v("2. 无OA账号成员请从供应商门户-注册-添加")
                      ]),
                      _c("span", [_vm._v("(添加完成后请申请项目标准角色)")])
                    ])
                  ])
                ]
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }