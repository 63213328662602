import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import manageInnerService from "@/service/manageInner-service";
export default {
  name: 'AddDiaBusiness',
  props: {
    formEditData: {
      type: Object,
      required: false
    },
    from: {
      type: String,
      required: false
    },
    tagList: {
      type: Array,
      required: true
    },
    prjId: {
      type: String,
      required: false
    }
  },
  data: function data() {
    return {
      isAddingNow: false,
      splitWord: '?,?',
      // 怕命名里面有逗号
      isContactUser: '2',
      organizationLoading: false,
      formRules: {
        orgId: [{
          required: true,
          message: '请选择所属组织',
          trigger: 'blur'
        }],
        userName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }, {
          min: 2,
          max: 20,
          message: '长度限制在2到20个字之间',
          trigger: 'blur'
        }],
        roleIds: [{
          required: true,
          message: '请选择角色',
          trigger: 'blur'
        }]
      },
      companyList: [],
      oldRoleName: '',
      authUserList: [],
      userLoading: false
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    changeroleName: function changeroleName(val) {
      var _this2 = this;

      this.tagList.filter(function (itemx) {
        if (val == itemx.roleId) {
          _this2.formEditData.roleName = itemx.roleName;
        }
      });
    },
    // 选择 所属组织事件
    getCompanyName: function getCompanyName(val) {
      var _this3 = this;

      this.companyList.filter(function (itemx) {
        if (val == itemx.id) {
          _this3.formEditData.department = itemx.name;
        }
      });
    },
    // 搜索 所属组织事件
    getCompanyList: function getCompanyList(query) {
      var _this4 = this;

      if (query !== '') {
        this.organizationLoading = true;
        var orgName = query;
        manageInnerService.getByOrgName(orgName).then(function (res) {
          _this4.organizationLoading = false;
          _this4.companyList = res.data;
        });
      } else {
        this.companyList = [];
      }
    },
    // 继续添加
    Continuetoadd: function Continuetoadd() {
      var _this5 = this;

      // 提交数据格式进行修改
      if (this.isAddingNow) return;
      this.isAddingNow = true; // 新增

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var params = {
            department: _this5.formEditData.department,
            orgId: _this5.formEditData.orgId,
            prjId: _this5.prjId,
            realName: _this5.formEditData.realName,
            roleCode: _this5.formEditData.roleIds,
            roleCodeOld: '',
            roleName: _this5.formEditData.roleName,
            userId: _this5.formEditData.userId,
            userName: _this5.formEditData.userName
          };
          manageInnerService.innerUsercreate(params).then(function (res) {
            if (res.result === '0') {
              _this5.$message.success('创建成功！');

              _this5.isAddingNow = false;

              _this5.$emit('close1');
            } else {
              _this5.isAddingNow = false;
            }
          });
        } else {
          _this5.isAddingNow = false;
        }
      });
    },
    // 添加授权 或者 编辑授权    
    saveUser: function saveUser() {
      var _this6 = this;

      // 提交数据格式进行修改
      if (this.isAddingNow) return;
      this.isAddingNow = true;

      if (this.from === 'add') {
        // 新增
        this.$refs.form.validate(function (valid) {
          if (valid) {
            var params = {
              department: _this6.formEditData.department,
              orgId: _this6.formEditData.orgId,
              prjId: _this6.prjId,
              realName: _this6.formEditData.realName,
              roleCode: _this6.formEditData.roleIds,
              roleCodeOld: '',
              roleName: _this6.formEditData.roleName,
              userId: _this6.formEditData.userId,
              userName: _this6.formEditData.userName
            };
            manageInnerService.innerUsercreate(params).then(function (res) {
              if (res.result === '0') {
                _this6.$message.success('创建成功！');

                _this6.isAddingNow = false;

                _this6.$emit('close');
              } else {
                _this6.isAddingNow = false;
              }
            });
          } else {
            _this6.isAddingNow = false;
          }
        });
      } else if (this.from === 'edit') {
        if (this.formEditData.roleIds) {
          var params1 = {
            department: this.formEditData.department,
            orgId: this.formEditData.orgId,
            prjId: this.prjId,
            realName: this.formEditData.realName,
            roleCode: this.formEditData.roleIds,
            roleCodeOld: this.formEditData.roleCode,
            roleName: this.formEditData.roleName,
            userId: this.formEditData.userId,
            userName: this.formEditData.userName
          };
          manageInnerService.outerUserupdateUser(params1).then(function (res) {
            _this6.isAddingNow = false;

            if (res.result === '0') {
              _this6.$message.success('成功！');

              _this6.$emit('close');
            } else {
              _this6.isAddingNow = false;
            }
          });
        } else {
          this.isAddingNow = false; // 说明还是以前的数据

          this.$message.success('成功！');
          this.$emit('close');
        }
      } else {
        this.isAddingNow = false;
      }
    },
    // 取消
    cancle: function cancle() {
      this.$emit('close');
    },
    clearValidates: function clearValidates() {
      var _this = this;

      setTimeout(function () {
        try {
          _this.$refs["form"].clearValidate();
        } catch (e) {}
      });
    },
    // 添加角色时 人员搜索
    getUserList: function getUserList(query) {
      var _this7 = this;

      if (query.trim()) {
        this.userLoading = true;
        manageInnerService.cityAdmingetAllUserList(query, this.prjId).then(function (res) {
          _this7.userLoading = false;
          _this7.authUserList = res.data;
        });
      } else {
        this.authUserList = [];
      }
    },
    changerealName: function changerealName(val) {
      var _this8 = this;

      this.authUserList.filter(function (itemx) {
        if (val == itemx.userName) {
          _this8.formEditData.userId = itemx.userId;
          _this8.formEditData.realName = itemx.realName;
        }
      });
    }
  }
};