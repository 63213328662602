var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage-outer" },
    [
      _c(
        "div",
        { staticClass: "partner-search" },
        [
          _c(
            "el-form",
            {
              ref: "searchCon",
              attrs: { model: _vm.formData, "label-width": "80px" }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", prop: "realName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入姓名" },
                            model: {
                              value: _vm.formData.realName,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "realName", $$v)
                              },
                              expression: "formData.realName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-right": "15px" },
                      attrs: { span: 4 }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "角色", prop: "roleName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入角色" },
                            model: {
                              value: _vm.formData.roleName,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "roleName", $$v)
                              },
                              expression: "formData.roleName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "组织", prop: "orgName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入组织" },
                            model: {
                              value: _vm.formData.orgName,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "orgName", $$v)
                              },
                              expression: "formData.orgName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "40px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "info", size: "small" },
                              on: { click: _vm.resetSearch }
                            },
                            [_vm._v("重置")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.getOuterUserList }
                            },
                            [_vm._v("搜索")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "partner-option" },
        [
          _vm.prjBuId != "X1"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.changeAddDia(1)
                    }
                  }
                },
                [_vm._v(" 角色授权 ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("p", { staticStyle: { padding: "10px 35px" } }, [_vm._v("普通人员")]),
      _c(
        "div",
        { staticClass: "partner-table", staticStyle: { padding: "0px 35px" } },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable01",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.commonRoleUsers, "tooltip-effect": "dark" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "realName",
                  label: "姓名(+OA账号)",
                  "class-name": "firstCol"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "组织", prop: "department" }
              }),
              _c("el-table-column", {
                attrs: { prop: "roleName", label: "角色" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.prjBuId != "X1"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.changeAddDia(scope.row)
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "reset-btn" }, [
                                  _vm._v("编辑")
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.prjBuId != "X1"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.delectUser(scope.row)
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "reset-btn" }, [
                                  _vm._v("删除")
                                ])
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "modal-append-to-body": false,
            title: _vm.diaTitle,
            visible: _vm.addDiaShow,
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.addDiaShow = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [
                  _c("span", [_vm._v(_vm._s(_vm.diaTitle))]),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "14px", color: "#E6A23C" } },
                    [_vm._v(" (仅用于产品审核临时添加人员)")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("add-partner", {
            ref: "addpartner",
            attrs: {
              tagList: _vm.tagList,
              formEditData: _vm.formEditData,
              from: _vm.from,
              prjId: _vm.prjId
            },
            on: {
              close: function($event) {
                return _vm.changeCloseAddDia(false)
              },
              close1: _vm.changeCloseAddDia1
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }