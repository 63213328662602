import _objectSpread from "F:/@company/lzz-productcheck-front/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import addPartner from './ManageOuterBusinessCom/AddDiaBusiness.vue'; // import addManyPartner from './ManageOuterBusinessCom/AddManyDiaBusiness.vue'

import manageInnerService from "@/service/manageInner-service";
import store from '@/store';
import { mapState } from "vuex";
export default {
  name: 'ManageOuterBusiness',
  components: {
    addPartner: addPartner // addManyPartner

  },
  data: function data() {
    return {
      diaTitle: '添加人员',
      from: 'add',
      formEditData: {},
      addDiaShow: false,
      addManyDiaShow: false,
      supplierName: '',
      textarea: '',
      formData: {
        realName: '',
        roleName: '',
        orgName: ''
      },
      commonRoleUsers: [],
      rowData: [],
      tagList: [],
      prjId: ''
    };
  },
  watch: {
    COEprjID: function COEprjID() {
      this.prjId = this.COEprjID || '';
      this.init();
    }
  },
  computed: _objectSpread({}, mapState({
    COEprjID: function COEprjID(state) {
      return state.prj.prjId;
    },
    prjBuId: function prjBuId(state) {
      return state.prj.prjBuId;
    }
  })),
  created: function created() {
    this.prjId = this.$store.state.prj.prjId || '';
    this.init(); // if(!!this.prjId){
    //   this.init()
    // }
    // if (!!this.$route.query.prjID){
    //   this.prjId = this.$route.query.prjID || ''
    //   this.init()
    // }else {
    //   this.$message('缺少项目id...');
    // }
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      store.dispatch('onLoading', false);
    });
  },
  methods: {
    // 初始化判断
    init: function init() {
      this.getOuterUserList();
      this.getTagList();
    },
    // 角色数据
    getTagList: function getTagList() {
      var _this = this;

      var roleName = '';
      manageInnerService.verifygetTagsList(roleName).then(function (res) {
        var data = [];

        if (res.result === '0') {
          // data = res.data
          for (var i = 0; i < res.data.length; i++) {
            res.data[i].leaf = false;

            if (res.data[i].isOuter === '1') {
              data.push(res.data[i]);
            }
          }
        }

        _this.tagList = data; // console.log(this.tagList);
      });
    },
    // 外部人员列表
    getOuterUserList: function getOuterUserList() {
      var _this2 = this;

      var prjId = this.prjId;
      var data = {
        orgCondition: this.formData.orgName,
        prjId: prjId,
        roleCondition: this.formData.roleName,
        userCondition: this.formData.realName
      };
      manageInnerService.outerRoleUsers(data).then(function (res) {
        if (res.result === '0') {
          _this2.commonRoleUsers = res.data;
        } else {
          _this2.commonRoleUsers = [];
        }
      });
    },
    // 重置密码按钮
    resetPassWord: function resetPassWord(item) {
      var _this3 = this;

      this.$confirm('你确定要更改密码么?', {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = {
          // mobile: item.userName,
          newPassword: "1",
          resetScene: "1",
          userId: _this3.userInfo.userId,
          userName: item.userName,
          userType: "2"
        };

        _this3.service.post(_this3.URL.suppResetPassword, data).then(function (res) {
          if (res.code === '200') {
            _this3.$message({
              type: 'success',
              message: '你已成功修改用户密码！'
            });
          } else {
            _this3.$message({
              type: '',
              message: res.data.respCode.message
            });
          }
        });
      }).catch(function () {});
    },
    // 删除按钮
    delectUser: function delectUser(item) {
      var _this4 = this;

      this.$confirm('你确定要删除么?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        manageInnerService.outerUserdeleteUser(item.userId, _this4.prjId, item.roleCode).then(function (res) {
          if (res.result === '0') {
            _this4.$message({
              type: 'success',
              message: '删除成功！'
            });

            _this4.getOuterUserList();
          }
        });
      }).catch(function () {});
    },
    // 重置
    resetSearch: function resetSearch() {
      this.$refs.searchCon.resetFields();
      this.formData.roleName = '';
      this.formData.orgName = '';
      this.getOuterUserList();
    },
    supplierChange: function supplierChange(a) {
      this.formData.supperId = a.code;
    },
    // open 批量授权
    changeManyDia: function changeManyDia(val) {
      this.addManyDiaShow = val;
    },
    // 点击角色授权 按钮
    changeAddDia: function changeAddDia(item) {
      var _this5 = this;

      // this.getOuterUserList() // 每次赋予oldcode的值
      this.formEditData = _objectSpread(_objectSpread({}, item), {}, {
        supperId: item.department,
        realName: item.realName,
        roleIds: item.roleName
      });

      if (item === 1) {
        this.diaTitle = "角色授权";
        this.from = "add";
      } else {
        this.formEditData = _objectSpread({}, item);
        this.diaTitle = "角色编辑";
        this.from = 'edit';
      }

      this.addDiaShow = true;
      this.$nextTick(function () {
        _this5.$refs.addpartner.clearValidates();
      });
    },
    // 关闭 角色授权 单个
    changeCloseAddDia: function changeCloseAddDia() {
      this.addDiaShow = false;
      this.getOuterUserList();
    },
    // 继续添加
    changeCloseAddDia1: function changeCloseAddDia1() {
      this.formEditData = {};
      this.getOuterUserList();
    },
    // 关闭 角色授权 批量
    changeCloseManyDia: function changeCloseManyDia() {
      this.addManyDiaShow = false;
    }
  }
};